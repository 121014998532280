import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { format, parseISO } from "date-fns"

import SEO from "../../../components/shared/SEO"
import Icon from "../../../dsm/icons/icon"
import Inner from "../../../dsm/layout/Inner"
import Layout from "../../../dsm/layout"
import Button from "../../../dsm/buttons"
import InnerSkinny from "../../../dsm/layout/InnerSkinny"
import SocialSharing from "./SocialSharing"
import FluidBackgroundImage from "../../../components/Images/FluidBackgroundImage"
import getFlexiOptionsLayouts from "../../../options/getFlexiOptionsLayouts"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"
import {
  CreateLocalLink,
  getVerticalSpacing,
  getInverseColour,
  getColorLuminance,
} from "../../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const PostSingleLayoutA = ({ pageContext, blogHeaderConfig }) => {
  const { lightSkin, primaryColor, shape } = getFlexiOptionsBranding()

  const {
    post: { title, content, featuredImage, seo, author, date, categories },
    prevPost,
    nextPost,
  } = pageContext

  const fontColour = blogHeaderConfig.textColour || "#fff"
  const shareLink =
    process.env.GATSBY_SITE_URL + CreateLocalLink(pageContext.post.link)

  const {
    websiteSkeleton: { mainBgColor },
  } = getFlexiOptionsLayouts()

  return (
    <Layout>
      <SEO seo={seo} />
      <Wrapper
        lightSkin={lightSkin}
        mainBgColor={mainBgColor}
        primaryColor={primaryColor}
      >
        <BlogHeader
          shape={shape}
          image={featuredImage}
          fontColour={fontColour}
          vSpacing={getVerticalSpacing(blogHeaderConfig.verticalSpacing)}
        >
          <InnerSkinny>
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            {blogHeaderConfig.showAuthor && (
              <div className="author">
                <img
                  src={author.avatar.url}
                  alt={author.name}
                  className="avatar"
                />
                {author.name}
              </div>
            )}
            {blogHeaderConfig.showDate && (
              <div className="date">
                <Icon name="calendar" hue="tusk" shade="200" />
                {format(parseISO(date), "d MMMM yyyy")}
              </div>
            )}
            {blogHeaderConfig.showCategories && (
              <div className="categories">
                {categories.nodes.map((cat) => (
                  <Button
                    to={CreateLocalLink(cat.link)}
                    type="secondary"
                    label={cat.name}
                  />
                ))}
              </div>
            )}
          </InnerSkinny>
          {blogHeaderConfig.showOverlay && <div className="overlay" />}
        </BlogHeader>
        <InnerSkinny>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="blog-single-content"
          />
        </InnerSkinny>
        <SocialSharing link={shareLink} />
        {(prevPost || nextPost) && (
          <PostsNavigation
            primaryColor={primaryColor}
            mainBgColor={mainBgColor}
          >
            <Inner>
              {prevPost ? (
                <Link to={CreateLocalLink(prevPost.link)} className="prev">
                  <div>
                    <span></span>
                    <span>Previous</span>
                    <Icon name="arrow-left" hue="tusk" shade="300" />
                    <p dangerouslySetInnerHTML={{ __html: prevPost.title }} />
                  </div>
                </Link>
              ) : (
                <div className="spacer" />
              )}
              {nextPost ? (
                <Link to={CreateLocalLink(nextPost.link)} className="next">
                  <div>
                    <span>Next</span>
                    <span></span>
                    <p dangerouslySetInnerHTML={{ __html: nextPost.title }} />
                    <Icon name="arrow-right" hue="tusk" shade="300" />
                  </div>
                </Link>
              ) : (
                <div className="spacer" />
              )}
            </Inner>
          </PostsNavigation>
        )}
      </Wrapper>
    </Layout>
  )
}
export default PostSingleLayoutA

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Wrapper = styled.div`
  background: ${({ theme, lightSkin, mainBgColor }) =>
    mainBgColor
      ? mainBgColor
      : lightSkin
      ? theme.colors.tusk[200]
      : theme.colors.midnight[500]};
  .blog-single-content {
    padding: 40px 0;
    h1,
    h2,
    h3 {
      line-height: 1.1;
    }
    blockquote {
      background-color: ${({ theme, lightSkin }) =>
        lightSkin ? theme.colors.white[100] : theme.colors.midnight[300]};
      border-left: 3px solid ${({ primaryColor }) => primaryColor};
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding: 20px 30px 20px 40px;
      border-radius: 4px;
      quotes: "“" "”" "‘" "’";
      position: relative;
      margin: 40px -10px;
      &::before {
        content: open-quote;
        color: ${({ primaryColor }) => getInverseColour(primaryColor)};
        background-color: ${({ primaryColor }) => primaryColor};
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 3rem;
        line-height: 1.2;
        left: -20px;
        top: calc(50% - 20px);
        display: grid;
        align-items: center;
        justify-content: center;
      }
      em {
        margin-top: 20px;
        display: block;
      }
    }
    a {
      overflow-wrap: break-word;
      word-break: break-word;
    }
    .video-container {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
    }
    .video-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    img,
    .wp-caption.aligncenter {
      max-width: calc(100% + 80px);
      border-radius: 4px;
      margin: 20px -20px 10px -20px;
      text-align: center;
    }
    .wp-caption-text {
      background-color: ${({ theme, lightSkin }) =>
        lightSkin ? theme.colors.tusk[300] : theme.colors.midnight[100]};
      display: inline-block;
      border-radius: 4px;
      padding: 5px 8px;
      font-size: 0.8rem;
      margin: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .blog-single-content {
      blockquote {
        margin: 40px 0 40px 10px;
      }
      img,
      .wp-caption.aligncenter {
        max-width: 100%;
        margin: 20px 0 10px 0;
      }
    }
  }
`

const BlogHeader = styled(FluidBackgroundImage)`
  padding: ${({ vSpacing }) => `${vSpacing} 0`};
  position: relative;
  h1 {
    margin: 0;
    color: ${({ fontColour }) => fontColour};
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .inner {
    text-align: center;
    z-index: 2;
    position: relative;
  }
  .author {
    color: ${({ fontColour }) => fontColour};
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    .avatar {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
  }
  .date {
    color: ${({ fontColour }) => fontColour};
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    font-size: 0.8rem;
  }
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }
  .categories a {
    margin: 5px;
  }
`
const PostsNavigation = styled.div`
  background: ${({ theme, mainBgColor }) =>
    mainBgColor
      ? getColorLuminance(mainBgColor, -0.1)
      : theme.colors.midnight[200]};
  .inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
  }
  div {
    padding: 5px 0;
    display: grid;
    grid-gap: 20px;
    grid-row-gap: 5px;
    align-items: center;
  }
  .prev {
    justify-self: flex-start;
    div {
      grid-template-columns: 20px 1fr;
    }
  }
  .next {
    justify-self: flex-end;
    text-align: right;
    div {
      grid-template-columns: 1fr 20px;
    }
  }
  a {
    color: #fff;
    border: none;
    padding: 20px;
    display: block;
    width: calc(100% - 33px);
    transition: all 200ms ease-in-out;
    background: ${({ theme, mainBgColor }) =>
      mainBgColor
        ? getColorLuminance(mainBgColor, -0.1)
        : theme.colors.midnight[200]};
    border-radius: 4px;
    &:hover {
      background: ${({ theme, mainBgColor }) =>
        mainBgColor
          ? getColorLuminance(mainBgColor, 0.1)
          : theme.colors.midnight[300]};
      border: none;
      cursor: pointer;
    }
    span {
      color: ${({ primaryColor }) => primaryColor};
      text-transform: uppercase;
      font-size: 0.9rem;
    }
    #icon {
      fill: ${({ primaryColor }) => primaryColor};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    .inner {
      grid-template-columns: 1fr;
    }
    .next {
      background-color: ${({ theme }) => theme.colors.midnight[300]};
      &:hover {
        background-color: ${({ theme }) => theme.colors.midnight[400]};
      }
    }
    .spacer {
      display: none;
    }
  }
`
