const PostTemplateFragment = `
    fragment PostTemplateFragment on WPGraphQL_Post {
        id
        postId
        title
        slug
        uri
        link
        date
        excerpt
        content
        featuredImage {
            altText
            sourceUrl
            imageFile {
                childImageSharp {
                fluid(quality: 90, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                }
            }
        }
        author {
            uri
            name
            slug
            avatar {
                url
            }
        }
        categories {
            nodes {
                id
                link
                name
          }
        }
        seo {
            ...SEOFields
        }
    }
`

module.exports.PostTemplateFragment = PostTemplateFragment
