import React from "react"
import styled from "styled-components"

import Icon from "../../../dsm/icons/icon"
import InnerSkinny from "../../../dsm/layout/InnerSkinny"
import { getColorLuminance } from "../../../utils"
import getFlexiOptionsLayouts from "../../../options/getFlexiOptionsLayouts"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const SocialSharing = ({ link }) => {
  const { lightSkin, shape } = getFlexiOptionsBranding()

  const {
    websiteSkeleton: { mainBgColor },
  } = getFlexiOptionsLayouts()

  return (
    <Wrapper lightSkin={lightSkin} shape={shape} mainBgColor={mainBgColor}>
      <InnerSkinny>
        <a
          href={`https://twitter.com/intent/tweet?text=${link}`}
          target="_blank"
          rel="noreferrer noopener"
          className="twitter"
        >
          <Icon name="twitter" hue="tusk" shade="600" />
          Share on Twitter
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
          target="_blank"
          rel="noreferrer noopener"
          className="facebook"
        >
          <Icon name="facebook" hue="tusk" shade="600" />
          Share on Facebook
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${link}`}
          target="_blank"
          rel="noreferrer noopener"
          className="linkedin"
        >
          <Icon name="linkedin" hue="tusk" shade="600" />
          Share on LinkedIn
        </a>
      </InnerSkinny>
    </Wrapper>
  )
}
export default SocialSharing

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Wrapper = styled.div`
  background: ${({ theme, lightSkin, mainBgColor }) =>
    mainBgColor
      ? getColorLuminance(mainBgColor, 0.2)
      : lightSkin
      ? theme.colors.tusk[300]
      : theme.colors.midnight[500]};
  padding: 20px 0;
  .inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  a {
    color: ${({ theme }) => theme.colors.tusk[600]};
    background: ${({ theme, lightSkin, mainBgColor }) =>
      mainBgColor
        ? getColorLuminance(mainBgColor, 0.5)
        : lightSkin
        ? theme.colors.tusk[100]
        : theme.colors.midnight[400]};
    padding: 20px;
    border-radius: 4px;
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    justify-content: center;
    border: none;
    &:hover {
      border: none;
      color: #fff;
      #icon {
        fill: #fff;
      }
    }
    &.facebook:hover {
      background-color: #3c5a99;
    }
    &.twitter:hover {
      background-color: #1da1f2;
    }
    &.linkedin:hover {
      background-color: #0077b5;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    .inner {
      grid-template-columns: 1fr;
    }
  }
`
