import React from "react"
import PostSingleLayoutA from "./single/LayoutA"
import getFlexiOptionsLayouts from "../../options/getFlexiOptionsLayouts"
import withWPRevisionQuery from "../../components/WPLiveGatsby/withWPRevisionQuery"
import config from "../../components/WPLiveGatsby/config/post-revisions.config"
import useWPRevision from "../../components/WPLiveGatsby/useWPRevision"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const PostSingle = ({ pageContext, previewQuery }) => {
  const { blogHeaderConfig } = getFlexiOptionsLayouts()
  var { revision } = useWPRevision(previewQuery)

  // Swap out Layout
  let Layout = PostSingleLayoutA
  switch (blogHeaderConfig.singlePageHeaderLayout) {
    case "a":
      Layout = PostSingleLayoutA
      break
    default:
      Layout = PostSingleLayoutA
      break
  }

  var payload = revision ? { ...pageContext, post: revision } : pageContext

  return <Layout pageContext={payload} blogHeaderConfig={blogHeaderConfig} />
}
export default withWPRevisionQuery(config)(PostSingle)
