import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const FluidBackgroundImage = ({ image, children, ...props }) => {
  /**
   * Return fallback colour, if no Image is given.
   */
  if (!image) {
    return <Blank {...props}>{children}</Blank>
  }

  if (image && image.imageFile) {
    return (
      <BackgroundImage
        fluid={image.imageFile.childImageSharp.fluid}
        alt={image.altText}
        {...props}
      >
        {children}
      </BackgroundImage>
    )
  }

  return (
    <BackgroundImage src={image.sourceUrl} alt={image.altText} {...props} />
  )
}
export default FluidBackgroundImage

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Blank = styled.div`
  background: ${({ theme }) => theme.colors.midnight[300]};
`
