import React from "react"
import styled from "styled-components"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const InnerSkinny = ({ children }) => (
  <InnerWrapper className="inner">{children}</InnerWrapper>
)
export default InnerSkinny

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const InnerWrapper = styled.div`
  max-width: ${({ theme }) => theme.maxWidthSkinny};
  margin: 0 auto;
  padding: 0 20px;
`
